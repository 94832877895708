import { DeprecatedFormNodeType, FormNodeType } from "@/services/api";

enum Tab {
	PROPERTIES = 'properties',
	ACTIVATORS = 'activators',
	VALIDATORS = 'validators',
	ACTIONS = 'actions',
	CONTENT = 'contenttab'
}

enum PropertiesType {
	BASE = 'BaseProperties',
	BASE_NO_VARIANT = 'BaseNoVariantProperties',
	CHECKBOX_GROUP = "CheckboxGroupProperties",
	DEPRECATED = 'DeprecatedProperties',
	INPUT = 'InputProperties',
	PAGE = 'PageProperties',
	RADIO = 'RadioGroupProperties',
	RADIO_YES_NO = 'RadioGroupYesNoProperties',
	SELECT = 'SelectProperties',
	SNIPPET = 'SnippetProperties',
	TEXTAREA = 'TextAreaProperties',
}

type FormNodeTypes = FormNodeType | DeprecatedFormNodeType;

interface ElementConfig {
	renderedElement: string;
	settingsTabs: Tab[];
	properties: PropertiesType;
	data?: Record<string, unknown>;
}

const currentYear = (new Date()).getFullYear(); 
const Elements: Record<string, ElementConfig> = {
	[FormNodeType.page]: {
		renderedElement:'BaseElement',
		settingsTabs: [Tab.PROPERTIES, Tab.ACTIVATORS, Tab.ACTIONS],
		properties: PropertiesType.PAGE
	},
	[FormNodeType.section]: {
		renderedElement:'SectionElement',
		settingsTabs: [Tab.PROPERTIES, Tab.ACTIVATORS],
		properties: PropertiesType.BASE
	},

	// Text
	[FormNodeType.input]: {
		renderedElement:'BaseInputElement',
		settingsTabs: [Tab.PROPERTIES, Tab.VALIDATORS, Tab.ACTIVATORS],
		properties: PropertiesType.INPUT
	},
	[FormNodeType.fundingAmount]: {
		renderedElement:'FundingAmountElement',
		settingsTabs: [Tab.PROPERTIES, Tab.VALIDATORS, Tab.ACTIVATORS],
		properties: PropertiesType.BASE_NO_VARIANT
	},
	[FormNodeType.numericInput]: {
		renderedElement:'BaseInputElement',
		settingsTabs: [Tab.PROPERTIES, Tab.VALIDATORS, Tab.ACTIVATORS],
		properties: PropertiesType.BASE_NO_VARIANT
	},
	[FormNodeType.password]: {
		renderedElement:'PasswordElement',
		settingsTabs: [Tab.PROPERTIES, Tab.VALIDATORS, Tab.ACTIVATORS],
		properties: PropertiesType.BASE_NO_VARIANT
	},
	[FormNodeType.longPhone]: {
		renderedElement:'PhoneElement',
		settingsTabs: [Tab.PROPERTIES, Tab.VALIDATORS, Tab.ACTIVATORS],
		properties: PropertiesType.BASE_NO_VARIANT
	},
	[FormNodeType.productFundingAmount]: {
		renderedElement:'ProductFundingAmountElement',
		settingsTabs: [Tab.PROPERTIES, Tab.VALIDATORS, Tab.ACTIVATORS],
		properties: PropertiesType.BASE
	},
	[FormNodeType.ssn]: {
		renderedElement:'SocialSecurityNumberElement',
		settingsTabs: [Tab.PROPERTIES, Tab.VALIDATORS, Tab.ACTIVATORS],
		properties: PropertiesType.BASE_NO_VARIANT
	},

	// TextArea
	[FormNodeType.textArea]: {
		renderedElement:'TextAreaElement',
		settingsTabs: [Tab.PROPERTIES, Tab.VALIDATORS, Tab.ACTIVATORS],
		properties: PropertiesType.BASE_NO_VARIANT
	},

	// Checkbox
	[FormNodeType.checkbox]: {
		renderedElement:'CheckboxElement',
		settingsTabs: [Tab.PROPERTIES, Tab.VALIDATORS, Tab.ACTIVATORS],
		properties: PropertiesType.BASE
	},
	[FormNodeType.checkboxGroup]: {
		renderedElement:'CheckboxGroupElement',
		settingsTabs: [Tab.PROPERTIES, Tab.VALIDATORS, Tab.ACTIVATORS],
		properties: PropertiesType.CHECKBOX_GROUP
	},
	[FormNodeType.esignCheckbox]: {
		renderedElement:'CheckboxElement',
		settingsTabs: [Tab.PROPERTIES, Tab.VALIDATORS, Tab.ACTIVATORS],
		properties: PropertiesType.BASE_NO_VARIANT
	},
	[FormNodeType.overdraftCheckbox]: {
		renderedElement:'CheckboxElement',
		settingsTabs: [Tab.PROPERTIES, Tab.VALIDATORS, Tab.ACTIVATORS],
		properties: PropertiesType.BASE_NO_VARIANT
	},
	[FormNodeType.usCitizenSection]: {
		renderedElement:'CheckboxUSCitizenElement',
		settingsTabs: [Tab.ACTIVATORS],
		properties: PropertiesType.BASE
	},

	// Select
	[FormNodeType.selectCustom]: {
		renderedElement:'SelectElement',
		settingsTabs: [Tab.PROPERTIES, Tab.VALIDATORS, Tab.ACTIVATORS],
		properties: PropertiesType.SELECT
	},
	[FormNodeType.selectCountry]: {
		renderedElement:'SelectElement',
		settingsTabs: [Tab.PROPERTIES, Tab.VALIDATORS, Tab.ACTIVATORS],
		properties: PropertiesType.SELECT,
		data: { firstOption: 'United States', description: 'United States, followed by remaining world countries in alphabetical order' }
	},
	[FormNodeType.selectDate]: {
		renderedElement:'SelectElement',
		settingsTabs: [Tab.PROPERTIES, Tab.VALIDATORS, Tab.ACTIVATORS],
		properties: PropertiesType.SELECT,
		data: { firstOption: '1', description: '1-31, in ascending order' }
	},
	[FormNodeType.date1900ThruCurrentYear]: {
		renderedElement:'DateElement',
		settingsTabs: [Tab.PROPERTIES, Tab.VALIDATORS, Tab.ACTIVATORS],
		properties: PropertiesType.BASE_NO_VARIANT
	},
	[FormNodeType.dateCurrentYearThruFuture]: {
		renderedElement:'DateElement',
		settingsTabs: [Tab.PROPERTIES, Tab.VALIDATORS, Tab.ACTIVATORS],
		properties: PropertiesType.BASE_NO_VARIANT
	},
	[FormNodeType.selectState]: {
		renderedElement:'SelectElement',
		settingsTabs: [Tab.PROPERTIES, Tab.VALIDATORS, Tab.ACTIVATORS],
		properties: PropertiesType.SELECT,
		data: { firstOption: 'AK - Alaska', description: 'All U.S. states in alphabetical order by abbreviation' }
	},
	[FormNodeType.selectSuffix]: {
		renderedElement:'SelectElement',
		settingsTabs: [Tab.PROPERTIES, Tab.VALIDATORS, Tab.ACTIVATORS],
		properties: PropertiesType.SELECT,
		data: { firstOption: 'Jr.', description: 'Jr., Sr., I, II, III, IV, V, VI, VII, VIII, IX' }
	},
	[FormNodeType.selectTitle]: {
		renderedElement:'SelectElement',
		settingsTabs: [Tab.PROPERTIES, Tab.VALIDATORS, Tab.ACTIVATORS],
		properties: PropertiesType.SELECT,
		data: { firstOption: 'Dr.', description: 'Dr., Mr., Ms., Mrs., Miss' }
	},
	[FormNodeType.selectYesNo]: {
		renderedElement:'SelectElement',
		settingsTabs: [Tab.PROPERTIES, Tab.VALIDATORS, Tab.ACTIVATORS],
		properties: PropertiesType.SELECT,
		data: { firstOption: 'No', description: 'No, Yes' }
	},
	[FormNodeType.select1900ThruCurrentYear]: {
		renderedElement:'SelectElement',
		settingsTabs: [Tab.PROPERTIES, Tab.VALIDATORS, Tab.ACTIVATORS],
		properties: PropertiesType.SELECT,
		data: { firstOption: currentYear.toString(), description: '1900-' + currentYear + ' in descending order' }
	},
	[FormNodeType.selectCurrentYearThruPlus25]: {
		renderedElement:'SelectElement',
		settingsTabs: [Tab.PROPERTIES, Tab.VALIDATORS, Tab.ACTIVATORS],
		properties: PropertiesType.SELECT,
		data: { firstOption: currentYear.toString(), description: currentYear + '-' + ( currentYear + 25) + ' in ascending order' }
	},

	// Radio
	[FormNodeType.radioGroupCustom]: {
		renderedElement:'RadioGroupElement',
		settingsTabs: [Tab.PROPERTIES, Tab.VALIDATORS, Tab.ACTIVATORS],
		properties: PropertiesType.RADIO
	},
	[FormNodeType.accountHolderRadio]: {
		renderedElement:'RadioGroupElement',
		settingsTabs: [Tab.PROPERTIES, Tab.VALIDATORS, Tab.ACTIVATORS],
		properties: PropertiesType.BASE,
		data: { options: ['[Primary Applicant Name]', '[Join Applicant Name]' ]}
	},
	[FormNodeType.radioGroupYesNo]: {
		renderedElement:'RadioGroupElement',
		settingsTabs: [Tab.PROPERTIES, Tab.VALIDATORS, Tab.ACTIVATORS],
		properties: PropertiesType.RADIO_YES_NO,
		data: { options: ['No', 'Yes' ]}
	},
	[FormNodeType.radioGroupProduct]: {
		renderedElement:'RadioGroupElement',
		settingsTabs: [Tab.PROPERTIES, Tab.VALIDATORS, Tab.ACTIVATORS],
		properties: PropertiesType.BASE_NO_VARIANT,
		data: { options: [
			'I only want to open [CASH_PRODUCT]',
			'I want to open [CASH_PRODUCT] and [SAVER_PRODUCT]',
			'I already have a [CASH_PRODUCT] with [CLIENT_NAME], and only want to open [SAVER_PRODUCT]',
		]}
	},

	// Special
	[FormNodeType.applicantInfoReview]: {
		renderedElement:'ApplicantInfoReviewElement',
		settingsTabs: [Tab.PROPERTIES, Tab.ACTIVATORS],
		properties: PropertiesType.BASE
	},
	[FormNodeType.plaidVerification]: {
		renderedElement:'PlaidVerificationElement',
		settingsTabs: [Tab.PROPERTIES, Tab.ACTIVATORS],
		properties: PropertiesType.BASE_NO_VARIANT
	},
	[FormNodeType.cardFundingSection]: {
		renderedElement:'CardFundingSectionElement',
		settingsTabs: [Tab.PROPERTIES, Tab.VALIDATORS, Tab.ACTIVATORS],
		properties: PropertiesType.BASE_NO_VARIANT
	},
	[FormNodeType.customHtml]: {
		renderedElement:'CustomHtmlElement',
		settingsTabs: [Tab.CONTENT, Tab.PROPERTIES, Tab.ACTIVATORS],
		properties: PropertiesType.BASE
	},
	[FormNodeType.customSnippet]: {
		renderedElement:'CustomSnippetElement',
		settingsTabs: [Tab.PROPERTIES, Tab.VALIDATORS, Tab.ACTIVATORS],
		properties: PropertiesType.SNIPPET,
	},
	[FormNodeType.demonstrableConsent]: {
		renderedElement:'DemonstrableConsentElement',
		settingsTabs: [Tab.PROPERTIES, Tab.ACTIVATORS],
		properties: PropertiesType.BASE
	},
	[FormNodeType.disclosureLinks]: {
		renderedElement:'DisclosureLinksElement',
		settingsTabs: [Tab.ACTIVATORS],
		properties: PropertiesType.BASE
	},
	[FormNodeType.duration]: {
		renderedElement:'DurationElement',
		settingsTabs: [Tab.PROPERTIES, Tab.VALIDATORS, Tab.ACTIVATORS],
		properties: PropertiesType.BASE_NO_VARIANT
	},
	[FormNodeType.eligibilitySnippet]: {
		renderedElement:'EligibilitySnippetElement',
		settingsTabs: [Tab.PROPERTIES, Tab.VALIDATORS, Tab.ACTIVATORS],
		properties: PropertiesType.BASE
	},
	[FormNodeType.esignSection]: {
		renderedElement:'EsignSectionElement',
		settingsTabs: [Tab.ACTIVATORS],
		properties: PropertiesType.BASE
	},
	[FormNodeType.jointOow]: {
		renderedElement:'OowElement',
		settingsTabs: [Tab.PROPERTIES, Tab.ACTIVATORS],
		properties: PropertiesType.BASE
	},
	[FormNodeType.oow]: {
		renderedElement:'OowElement',
		settingsTabs: [Tab.PROPERTIES, Tab.ACTIVATORS],
		properties: PropertiesType.BASE
	},
	[FormNodeType.overdraftSection]: {
		renderedElement:'OverdraftSectionElement',
		settingsTabs: [Tab.PROPERTIES, Tab.ACTIVATORS],
		properties: PropertiesType.BASE
	},
	[FormNodeType.noCaptcha]: {
		renderedElement:'NoCaptchaElement',
		settingsTabs: [Tab.PROPERTIES, Tab.ACTIVATORS],
		properties: PropertiesType.BASE
	},
	[FormNodeType.patriotAct]: {
		renderedElement:'PatriotActElement',
		settingsTabs: [Tab.ACTIVATORS],
		properties: PropertiesType.BASE
	},
	[FormNodeType.selectFundingAccountHolderName]: {
		renderedElement:'SelectElement',
		settingsTabs: [Tab.PROPERTIES, Tab.VALIDATORS, Tab.ACTIVATORS],
		properties: PropertiesType.SELECT,
		data: { value: '[Primary Applicant\'s Name]', description: '[Primary Applicant\'s Name], [Joint Applicant\'s Name]' }
	},
	[FormNodeType.submit]: {
		renderedElement:'SubmitElement',
		settingsTabs: [Tab.PROPERTIES, Tab.ACTIVATORS],
		properties: PropertiesType.BASE_NO_VARIANT
	},

	// Deprecated - custom properties
	[DeprecatedFormNodeType.recaptcha]: {
		renderedElement: 'DeprecatedElement',
		settingsTabs: [Tab.PROPERTIES],
		properties: PropertiesType.DEPRECATED
	},
};

const getElementConfig = function(type: FormNodeTypes): ElementConfig {
	if (Elements[type]) {
		return Elements[type];
	}
	return {
		renderedElement: 'DeprecatedElement',
		// should we show all tabs for a deprecated element so folks can see what was there? Or just Properties?
		settingsTabs: [Tab.PROPERTIES, Tab.ACTIVATORS, Tab.VALIDATORS], 
		properties: PropertiesType.DEPRECATED
	} as ElementConfig;
};

export { getElementConfig, FormNodeTypes, Tab, PropertiesType, ElementConfig, Elements };
/* 
	todo:
		1. Group by which have static representations (e.g. OOW)
		2. Group by which have <input> representations (e.g. radio/checkbox/text...)
		2. determine which can have children
		3. Which use "Options"
		4. Get list of deprecated's and move out
*/
export enum FormNodeType {
	form = "form",
	page = "page",
	implicitSection = "implicitSection",
	section = "section",
	
	// Fields
	// input
	input = "input",
	password = "password",
	longPhone = "longPhone",
	numericInput = "numericInput",
	ssn = "ssn",
	fundingAmount = "fundingAmount",
	productFundingAmount = "productFundingAmount",

	// paragraph
	textArea = "textArea",
	
	// radios
	accountHolderRadio = "accountHolderRadio",
	radioGroupCustom = "radioGroupCustom",
	radioGroupProduct = "radioGroupProduct",
	radioGroupYesNo = "radioGroupYesNo",
	
	// checkboxes
	checkbox = "checkbox",
	checkboxGroup = "checkboxGroup",
	esignCheckbox = "esignCheckbox",
	overdraftCheckbox = "overdraftCheckbox",
	usCitizenSection = "usCitizenSection",
	
	// list
	selectCustom = "selectCustom",
	select1900ThruCurrentYear = "select1900ThruCurrentYear",
	selectCurrentYearThruPlus25 = "selectCurrentYearThruPlus25",
	selectCountry = "selectCountry",
	selectDate = "selectDate",
	selectState = "selectState",
	selectSuffix = "selectSuffix",
	selectTitle = "selectTitle",
	selectYesNo = "selectYesNo",
	date1900ThruCurrentYear = "date1900ThruCurrentYear",
	dateCurrentYearThruFuture = "dateCurrentYearThruFuture",
	
	// specials
	plaidVerification = "plaidVerification",
	applicantInfoReview = "applicantInfoReview",
	cardFundingSection = "cardFundingSection",
	customHtml = "customHtml",
	customSnippet = "customSnippet",
	duration = "duration",
	demonstrableConsent = "demonstrableConsent",
	disclosureLinks = "disclosureLinks",
	eligibilitySnippet = "eligibilitySnippet",
	esignSection = "esignSection",
	jointOow = "jointOow",
	oow = "oow",
	overdraftSection = "overdraftSection",
	noCaptcha = "noCaptcha",
	patriotAct = "patriotAct",
	selectFundingAccountHolderName = "selectFundingAccountHolderName",
	submit = "submit",
}

export const isImplicit = (type: FormNodeType | DeprecatedFormNodeType): boolean => {
	return type === FormNodeType.implicitSection;
};

export const isPageOrSection = (type: FormNodeType | DeprecatedFormNodeType): boolean => {
	return [FormNodeType.page, FormNodeType.section, FormNodeType.implicitSection].includes(type as FormNodeType);
};

export enum DeprecatedFormNodeType {
	// deprecated
	applicantIdentification = "applicantIdentification",
	checkboxCitizenshipUs = "checkboxCitizenshipUs",
	checkSection = "checkSection",
	electronicRecordsDisclosureLink = "electronicRecordsDisclosureLink",
	hidden = "hidden",
	phone = "phone",
	productDisclosuresSection = "productDisclosuresSection",
	radioGroupCitizenship = "radioGroupCitizenship",
	recaptcha = "recaptcha",
	reCaptchaThemes = "reCaptchaThemes",
	selectCitizenship = "selectCitizenship",
	selectDay = "selectDay",
	selectMonth = "selectMonth",
	radioGroupGiving = "radioGroupGiving",
	friendEmail = "friendEmail",
	friendNameAndEmail = "friendNameAndEmail",
	inmoFundingPreFundSection = "inmoFundingPreFundSection",
	inmoFundingSection = "inmoFundingSection",
}
import { FormNodeType, FormNodePresets } from '@/services/api';

import Checkbox from './Checkbox.vue';
import Input from './Input.vue';
import RadioGroupCustom from './RadioGroupCustom.vue';
import Section from './Section.vue';
import SelectCustom from './SelectCustom.vue';
import Special from './Special.vue';
import TextArea from './TextArea.vue';

export const FieldModules = {
	Checkbox,
	Input,
	RadioGroupCustom,
	Section,
	SelectCustom,
	Special,
	TextArea,
};

export interface FieldOption {
	label: string;
	type: FormNodeType;
	preset?: FormNodePresets;
	options?: FieldOption[];
}

const currentYear = (new Date()).getFullYear(); 

export const FieldLibraryList: FieldOption[] = [
	{ label: 'Section', type: FormNodeType.section },
	{ label: 'Text', type: FormNodeType.input,
		options: [
			{ label: 'Funding Amount', type: FormNodeType.fundingAmount,
				preset: { label: 'Amount to transfer\n(between MIN and MAX)' }
			},
			{ label: 'Numeric Input', type: FormNodeType.numericInput },
			{ label: 'Password', type: FormNodeType.password,
				preset: { label: 'Password' }
			},
			{ label: 'Phone (2 input fields)', type: FormNodeType.longPhone,
				preset: { label: 'Phone' }
			},
			{ label: 'Product Funding Amount', type: FormNodeType.productFundingAmount,
				preset: { label: 'Amount to transfer\n\nVerify Amount' }
			},
			{ label: 'Social Security Number', type: FormNodeType.ssn,
				preset: { label: 'Social Security Number' }
			},
		]
	},
	{ label: 'Paragraph\nText', type: FormNodeType.textArea },
	{ label: 'Checkbox', type: FormNodeType.checkbox,
		options: [
			{ label: 'Checkbox Group', type: FormNodeType.checkboxGroup,
				preset: { options: [{ label: 'Option 1' }, { label: 'Option 2' }] },
			},
			{ label: 'E-sign Disclosure', type: FormNodeType.esignCheckbox, 
				preset: { label: 'I have read and agreed to the <a href="URL" target="_blank">TITLE</a>' }
			},
			{ label: 'Overdraft Disclosure', type: FormNodeType.overdraftCheckbox, 
				preset: { label: 'I have read and agreed to the <a href="URL" target="_blank">TITLE</a>' }
			},
			{ label: 'U.S. Citizen Section', type: FormNodeType.usCitizenSection, 
				preset: { label: null }
			},
		]
	},
	{ label: 'List', type: FormNodeType.selectCustom,
		options: [
			{ label: 'Country', type: FormNodeType.selectCountry,
				preset: { label: 'Country' }
			},
			{ label: 'Date (1-31)', type: FormNodeType.selectDate,
				preset: { label: 'Date', beginWithEmptyOption: true }
			},
			{ label: 'Date (1900-' + currentYear + ')', type: FormNodeType.date1900ThruCurrentYear,
				preset: { label: 'Date' }
			},
			{ label: 'Date (' + currentYear + '-' + (currentYear + 50) + ')', type: FormNodeType.dateCurrentYearThruFuture,
				preset: { label: 'Date' }
			},
			{ label: 'State', type: FormNodeType.selectState,
				preset: { label: 'State', beginWithEmptyOption: true }
			},
			{ label: 'Suffix', type: FormNodeType.selectSuffix,
				preset: { label: 'Suffix', beginWithEmptyOption: true }
			},
			{ label: 'Title', type: FormNodeType.selectTitle,
				preset: { label: 'Title', beginWithEmptyOption: true }
			},
			{ label: 'Yes/No', type: FormNodeType.selectYesNo,
				preset: { label: 'Yes/No' }
			},
			{ label: 'Year (1900-' + currentYear + ')', type: FormNodeType.select1900ThruCurrentYear,
				preset: { label: 'Year', beginWithEmptyOption: true }
			},
			{ label: 'Year (' + currentYear + '-' + (currentYear + 25) + ')', type: FormNodeType.selectCurrentYearThruPlus25,
				preset: { label: 'Year', beginWithEmptyOption: true }
			},
		]
	},
	{ label: 'Multiple Choice', type: FormNodeType.radioGroupCustom,
		preset: { options: [{ label: 'Option 1' }, { label: 'Option 2' }] },
		options: [
			{ label: 'Funding Source Account Holder', type: FormNodeType.accountHolderRadio, 
				preset: { label: 'Funding Source Account Holder' }
			},
			{ label: 'Products', type: FormNodeType.radioGroupProduct,
				preset: { label: 'Please select one of the following:' }
			},
			{ label: 'Yes/No', type: FormNodeType.radioGroupYesNo,
				preset: { label: 'Question' }
			},
		]
	},
	{ label: 'Special', type: 'special' as FormNodeType,
		options: [
			{ label: 'Applicant Info Review', type: FormNodeType.applicantInfoReview },
			{ label: 'Card Funding Section', type: FormNodeType.cardFundingSection },
			{ label: 'Custom HTML', type: FormNodeType.customHtml,
				preset: { label: 'Custom HTML', showInLeadManager: false }
			},
			{ label: 'Custom Snippet', type: FormNodeType.customSnippet },
			{ label: 'Demonstrable Consent', type: FormNodeType.demonstrableConsent },
			{ label: 'Disclosure Links', type: FormNodeType.disclosureLinks },
			{ label: 'Duration', type: FormNodeType.duration,
				preset: { showInLeadManagerAs: 'Duration (months)' }
			},
			{ label: 'E-Sign Disclosure', type: FormNodeType.esignSection },
			{ label: 'Eligibility Snippet', type: FormNodeType.eligibilitySnippet },
			{ label: 'Funding Account Holder', type: FormNodeType.selectFundingAccountHolderName },
			{ label: 'Joint Out-of-wallet Questions', type: FormNodeType.jointOow },
			{ label: 'NoCaptcha', type: FormNodeType.noCaptcha },
			{ label: 'Overdraft Section', type: FormNodeType.overdraftSection },
			{ label: 'Patriot Act Disclosure', type: FormNodeType.patriotAct },
			{ label: 'Plaid Verification', type: FormNodeType.plaidVerification,
				preset: { label: 'Plaid Verification', showInLeadManager: true, optional: true } },
			{ label: 'Primary Out-of-Wallet Questions', type: FormNodeType.oow },
			{ label: 'Submit Button', type: FormNodeType.submit },
		]
	}
];